$theme: core;
@use "design-system" as ds;

.separator {
  @include ds.border(divider--subtle, $t: 0, $b: 1px, $theme: $theme);
  margin: 0 ds.spacing();

  &.inBox {
    margin: 0;
  }
}

.latestNewsBoxContainer {
  @include ds.card($theme: $theme, $collapsing: true);
  margin: ds.spacing(base) 0;
}
