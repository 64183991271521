$theme: core;
@use "design-system" as ds;

.divider {
  margin: ds.spacing() ds.spacing() 0 ds.spacing();
  @include ds.border(divider--subtle, $t: 1px, $theme: $theme);

  &.explaining,
  &.longform {
    @media (min-width: ds.$screen-size--small) {
      margin: ds.spacing(medium) ds.spacing(medium) 0 ds.spacing(medium);
    }
  }
}
