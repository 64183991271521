$theme: core;
@use "design-system" as ds;

.tweetContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include ds.pad-lr();
  @include ds.stack();

  &.explaining,
  &.longform & {
    @include ds.stack(regular-increased);
    @media (min-width: ds.$screen-size--small) {
      @include ds.pad-lr(medium);
    }
  }
}

.tweet {
  width: 100%;
}

// Override the widgets inline styling
.embeddedTweet > div:first-child {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

// Match iframe border-radius to tweet-card border-radius
.embeddedTweet {
  & iframe {
    border-radius: 12px;
  }
}
