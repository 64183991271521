$theme: core;
@use "design-system" as ds;

.actionsContainer {
  margin-top: ds.spacing(medium);
  @include ds.background(card--subtle, $theme: $theme);
  padding: ds.spacing(regular-increased) ds.spacing(regular-increased)
    ds.spacing(medium) ds.spacing(regular-increased);
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    @include ds.background(primary, $theme: $theme);
    left: -100px;
    right: -100px;
    top: -20px;
    height: 300px;
    clip-path: ellipse(50% 10% at top);
    position: absolute;
  }

  &.native {
    @include ds.border(divider--subtle, $t: 1px, $theme: $theme);
    @include ds.background(surface-primary, $namespace: ads, $theme: $theme);

    &:before {
      display: none;
    }
  }

  &.withSalesPoster {
    margin-top: 0;
    &:before {
      @include ds.background(card--commercial, $theme: $theme);
    }
  }
}

.contactInformation {
  margin-top: ds.spacing();
  @include ds.typography(meta, $theme);
  text-align: center;
  text-wrap: pretty;
  @include ds.text-color(secondary, $theme: $theme);
}

.contactLink {
  @include ds.body-link($theme);
}

.userActions {
  margin-top: ds.spacing(medium);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ds.spacing(base) ds.spacing(base);
  justify-content: center;
  align-items: center;
}
