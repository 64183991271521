$theme: core;
@use "design-system" as ds;

.bylineContainer {
  @include ds.typography(underline-2, $theme);
  @include ds.text-color(secondary, $theme: $theme);
  @include ds.pad-lr();
  @include ds.stack();
}

.explaining,
.longform {
  @media (min-width: ds.$screen-size--small) {
    @include ds.pad-lr(medium);
  }
}
