$theme: core;
@use "design-system" as ds;

.infoCard {
  @include ds.card($theme: $theme);
  display: flex;
  box-sizing: border-box;

  &.native {
    @include ds.background(surface-primary, $namespace: ads, $theme: $theme);
  }
}

.link {
  @include ds.text-color(primary, $theme: $theme);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ds.spacing(micro);
  width: 100%;
}

.imageContainer {
  padding: ds.spacing() ds.spacing();
  display: block;
  height: 60px;
  width: 60px;

  &.native {
    height: 80px;
    width: 80px;

    & img {
      border-radius: 50%;
      object-fit: cover;
      height: 80px;
      width: 80px;
    }
  }
}

.textContainer {
  flex-grow: 1;
  padding: ds.spacing() ds.spacing() ds.spacing() 0;
}

.vignette {
  @include ds.typography(h9, $theme);
  @include ds.text-color(brand, $theme: $theme);
  text-transform: uppercase;
  line-height: 1.2;
  white-space: nowrap;

  &.native {
    @include ds.text-color(secondary, $theme: $theme);
    text-transform: none;
  }
}

.separator {
  @include ds.text-color(brand, $theme: $theme);
  margin: 0 ds.spacing(micro);
  line-height: 1.2;

  &.native {
    @include ds.text-color(secondary, $theme: $theme);
  }
}

.title {
  @include ds.typography(h9, $theme);
  line-height: 1.2;
  overflow-wrap: anywhere;
  word-break: break-word;
}

.caretRightContainer {
  align-self: stretch;
  flex-grow: 0;
  flex-shrink: 0;
  width: ds.spacing(medium-increased);
  display: flex;
  align-items: center;
  justify-content: center;
  @include ds.border(divider--subtle, $l: 1px, $theme: $theme);
}

.caretIcon {
  @include ds.colorise-icon(ui, divider, $theme: $theme);
}

.needSpace {
  margin-bottom: ds.spacing("base");
}

.nativeInfoCardAnnonsLabel {
  @include ds.text-color(secondary, $theme: $theme);
  @include ds.typography(underline-2, $theme);
  margin-top: ds.spacing("base");
  text-transform: uppercase;
}
