$theme: core;
@use "design-system" as ds;

.premiumInformationBoxContainer {
  margin: ds.spacing() ds.spacing() 0 ds.spacing();
  @include ds.border(divider--subtle, $t: 1px, $theme: $theme);
}

.premiumInformationInnerContainer {
  margin-top: ds.spacing();
  @include ds.background(card--commercial, $theme: $theme);
  padding: ds.spacing(medium) ds.spacing();
  border-radius: ds.$border-radius--default;
}

.premiumInformationBoxTitle {
  @include ds.typography(heading-xl--messaging, $theme);
  text-align: center;
}

.premiumInformationBoxText {
  @include ds.stack(base);
  @include ds.typography(body-base--messaging, $theme);
  text-align: center;
}

.cta {
  @include ds.stack();
  display: flex;
  justify-content: center;
}
