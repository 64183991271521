$theme: core;
@use "design-system" as ds;

.subHeading {
  @include ds.pad-lr();
  @include ds.stack(medium);

  &.originalTitle {
    @include ds.typography(premium-h3, $theme: $theme);
    text-wrap: balance;
    text-align: center;

    scroll-margin-top: 60px;
  }
}

.longform,
.explaining {
  @media (min-width: ds.$screen-size--small) {
    @include ds.pad-lr(medium);
  }
}

.news,
.explaining,
.native {
  @include ds.typography(h2, $theme: $theme);
}

.explaining {
  @include ds.stack(large);
}

.longform {
  @include ds.typography(premium-h8, $theme: $theme);
}

.Timeline {
  &.isGrouped {
    position: relative;
    // Add a "dot" in front of the title
    // on grouped timeline subheadings
    &:before {
      content: "";
      position: absolute;
      left: (ds.spacing(base) * -1)-1px;
      @include ds.background(brand, $namespace: ui, $theme: $theme);
      width: ds.spacing();
      height: ds.spacing();
      border-radius: 50%;

      @media (min-width: ds.$screen-size--small) {
        // Push the dot down slightly in order to
        // compensate for larger subtitle front
        margin-top: 4px;
      }
    }

    &.isPremium {
      &:before {
        @include ds.background(premium, $namespace: ui, $theme: $theme);
      }
    }
  }
}

.isGrouped {
  &:first-child {
    // Do not add as much spacing on grouped subheadings
    // when they are the first item in the group
    @include ds.stack();
  }
}
