$theme: core;
@use "design-system" as ds;

.skeletonContainer {
  @include ds.card($theme: $theme, $collapsing: true);
  box-sizing: border-box;

  @media (min-width: ds.$screen-size--small) {
    border-radius: 12px;
    max-width: 550px;
  }

  display: flex;
  flex-direction: column;
  padding: ds.spacing();
  gap: ds.spacing();
  width: 100%;
}

.horizontalElements {
  display: flex;
  gap: 16px;
}

.verticalElements {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.xIcon {
  margin-left: auto;
}
