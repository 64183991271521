$theme: core;
@use "design-system" as ds;

.chevronDescriptionContainer {
  @include ds.background(tertiary, $theme: $theme);
  text-align: center;
  @include ds.pad(medium);
}

.chevronDescriptionText {
  display: block;
  @include ds.typography(h6, $theme);
  @include ds.text-color(tertiary, $theme: $theme);
}

.defaultIcon {
  @include ds.stack(micro);
  @include ds.colorise-icon(ui, brand, $theme: $theme);

  &.premiumIcon {
    @if $theme != ekonomi {
      @include ds.colorise-icon(ui, premium, $theme: $theme);
    }
  }
}
