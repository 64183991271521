$theme: core;
@use "design-system" as ds;

.factContainer {
  @include ds.background(card--subtle, $theme: $theme);
  border-radius: ds.$border-radius--default;
  padding: ds.spacing();

  margin: ds.spacing(regular-increased) ds.spacing(regular-increased) 0
    ds.spacing(regular-increased);

  &.native {
    @include ds.border(divider, $a: 1px, $theme: $theme);
    background-color: transparent;
  }
}

.title {
  @include ds.typography(premium-h3, $theme);
  @include ds.text-color(brand, $theme: $theme);
  text-align: center;

  &.premium {
    @include ds.text-color(premium, $theme: $theme);
  }
}

.body {
  @include ds.typography(h6, $theme);
  text-align: center;

  & p {
    @include ds.stack(base);
  }
}

.native {
  @include ds.border(divider, $a: 1px, $theme: $theme);
  background-color: transparent;
}
