$theme: core;
@use "design-system" as ds;

.tag {
  @include ds.typography(button-text-2, $theme);
  @include ds.text-color(brand, $theme: $theme);
  padding: ds.spacing(base) ds.spacing();
  display: block;
  border-radius: 100vi;
  @include ds.background(primary, $theme: $theme);
  transition:
    background-color 0.2s,
    color 0.2s;

  @if $theme == ekonomi {
    // to avoid a constrast problem in eko
    @include ds.background(tertiary, $namespace: text, $theme: $theme);
  }

  &:hover,
  &:focus {
    @include ds.element-background(brand, $theme: $theme);
    @include ds.text-color(tertiary, $theme: $theme);
  }

  &.primary {
    @include ds.typography(button-text, $theme);
  }
}

.linksContainer {
  overflow: hidden;
  @include ds.background(card--subtle, $theme: $theme);

  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ds.spacing(base);
  justify-content: center;
  padding: ds.spacing(medium) 16px;
  @include ds.border(divider--subtle, $t: 1px, $theme: $theme);
}
