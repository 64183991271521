$theme: core;
@use "design-system" as ds;

.container {
  @include ds.pad();
  @include ds.box-shadow(card, $theme: $theme);
  @include ds.border(premium, $l: 2px, $theme: $theme);
  border-radius: 0 ds.$border-radius--default ds.$border-radius--default 0;
  margin: ds.spacing();
}

.title {
  @include ds.typography(h3, $theme);
}

.link {
  @include ds.text-color(primary, $theme: $theme);
}

.vignette {
  @include ds.text-color(premium, $theme: $theme);
}

.footer {
  margin-top: ds.spacing(base);
  @include ds.typography(underline-2, $theme: $theme);
  @include ds.text-color(premium, $theme: $theme);
}
